.box-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.hide {
  display: none !important;
}

hr.invisible {
  opacity: 0;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.uppercase {
  text-transform: uppercase;
}

.capitalise {
  text-transform: capitalize;
}

.list-bare {
  margin: 0;
  padding: 0;
  list-style: none;
}

.relative {
  position: relative;
}

.u-auto {
  width: auto !important;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    .hide-#{$alias} {
      display: none !important;
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
