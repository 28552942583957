@use "sass:math";

.fa-before {
  margin-right: .75ch;
}

.fa-after {
  margin-left: .75ch;
}

.fa-yellow {
  color: $yellow;
}
