@import "config/config";
@import "helpers/images"; // image-aspect-ratio
@import "helpers/units"; // rem

/**
  * Markup:
  * <div aria-roledescription="carousel" aria-label="Carousel title"
  *      class="ui-component-carousel
  *             variant:{image*, markup}
  *             slide-width:{full*, content} // applies to `variant:markup` only
  *             bg:{white*, grey}"> // applies to `variant:markup` only
  *   <div>
  *     <{ul,ol} aria-live="polite" tabindex="0" id="carousel-prefix-id">
  *       // Background images
  *       <li id="carousel-prefix-slide-0" aria-roledescription="slide" aria-posinset="1" aria-setsize="n"
  *           data-img-mobile="" data-img="">
  *       </li>
  *       <li id="carousel-prefix-slide-1" aria-roledescription="slide" aria-posinset="2" aria-setsize="n" aria-hidden="true"
  *           data-img-mobile="" data-img="">
  *       </li>
  *
  *       // ...or picture tags
  *       <li id="carousel-prefix-slide-0" aria-roledescription="slide" aria-posinset="1" aria-setsize="n"
  *           data-srcset-1x="" data-srcset-2x="" data-srcsizes="">
  *         <picture></picture> // optional `picture` tag if not using background of `li`
  *       </li>
  *       <li id="carousel-prefix-slide-1" aria-roledescription="slide" aria-posinset="2" aria-setsize="n" aria-hidden="true"
  *           data-srcset-1x="" data-srcset-2x="" data-srcsizes="">
  *         <picture></picture>
  *       </li>
  *
  *       // ...or markup slides
  *       <li id="carousel-prefix-slide-0" aria-roledescription="slide" aria-posinset="1" aria-setsize="n">
  *         <div>content</div>
  *       </li>
  *       <li id="carousel-prefix-slide-1" aria-roledescription="slide" aria-posinset="2" aria-setsize="n" aria-hidden="true">
  *         <div>content</div>
  *       </li>
  *       ...
  *     </{ul,ol}>
  *
  *     <button aria-label="Previous slide" aria-controls="carousel-prefix-id">
  *       <i class="far fa-chevron-left fa-2x" aria-hidden="true"></i>
  *     </button>
  *     <button aria-label="Next slide" aria-controls="carousel-prefix-id">
  *       <i class="far fa-chevron-right fa-2x" aria-hidden="true"></i>
  *     </button>
  *   </div>
  *
  *   // Optional indicators
  *   <{ul,ol} class="carousel-indicators">
  *     <li>
  *       <button aria-label="Go to slide 1" aria-controls="carousel-prefix-id">
  *         <i class="fas fa-circle" aria-hidden="true"></i>
  *       </button>
  *     </li>
  *     <li>
  *       <button aria-label="Go to slide 2" aria-controls="carousel-prefix-id">
  *         <i class="far fa-circle" aria-hidden="true"></i>
  *       </button>
  *     </li>
  *     ...
  *   </{ul,ol}>
  *
  *   // Optional thumbnails
  *   <{ul,ol} class="carousel-thumbnails">
  *     <li data-img="">
  *       <button aria-label="Go to slide 1" aria-controls="carousel-prefix-id">
  *       </button>
  *     </li>
  *     ...
  *   </{ul,ol}>
  * </div>
  */
.ui-component-carousel {
  $thumbnails-margin-top: $space-xs;
  $thumbnails-size: sb-rem(75px);

  display: block;

  &:has(.carousel-indicators) {
    position: relative;
  }

  &,
  > div,
  [aria-live="polite"][tabindex],
  [aria-roledescription="slide"] > picture {
    height: 100%;
  }

  > div {
    position: relative; // for absolute `img`

    @include desktop-max {
      overflow-x: hidden; // prevent carousel list overflowing container
    }
  }


  [aria-live="polite"][tabindex] {
    display: flex;
    overflow-x: auto;
    margin: 0;
    padding: 0;
    outline: none;
    list-style-type: none;
    scrollbar-width: none; // FF
    max-height: calc(100vh - #{$thumbnails-size} + #{$thumbnails-margin-top});

    /* Disable native horizontal scrolling within the carousel */
    /* Allow scrolling only by tapping the arrow icon */
    /* Vertical scrolling of the parent component is preserved */
    touch-action: pan-y; 
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    [aria-roledescription="slide"] {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-shrink: 0;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;

      &, & > picture {
        width: 100%;
      }
    }

    ~ [aria-controls] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 0 $space-sm;
      background: transparent;
      color: #fff;
      border: none;
      font: inherit;
      outline: inherit;
      text-shadow: 0 0 sb-rem(5px) $black;
      cursor: pointer;

      &[aria-label="Previous slide"] {
        left: 0;
      }

      &[aria-label="Next slide"] {
        right: 0;
        justify-content: flex-end;
      }

      &[disabled] {
        @include forms--input-disabled;
        cursor: pointer;
      }

      @include desktop {
        opacity: 0;

        &:hover {
          opacity: 1; // Use `opacity` instead of `visibility` so buttons still respond to `:hover`
        }
      }
    }
  }

  &:not(.variant\:markup) {
    [aria-live="polite"][tabindex] {
      [aria-roledescription="slide"]:empty { // `:empty` because this only applies to background image slides
        @include image-aspect-ratio(1.5);
      }
    }
  }

  &.variant\:markup {
    $box-shadow-offset: $space-sm;

    &:has(.carousel-indicators) {
      margin-bottom: $space;
    }

    > div {
      @include desktop-max {
        // `overflow-x: hidden` above causes this element to collapse, so match child's padding to
        // keep box-shadow visible
        padding-bottom: $box-shadow-offset;
      }
    }

    [aria-live="polite"][tabindex] {
      column-gap: $space; // override elsewhere for bigger/smaller gap
      padding-bottom: $box-shadow-offset;
      margin-bottom: calc(-1 * #{$box-shadow-offset});

      ~ [aria-controls] {
        width: auto;
        color: $black;

        > i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 0;
          height: 0;
          padding: $space;
          background: rgba(#fff, .5);
          border: 1px solid $black;
          border-radius: 100%;
          box-shadow: $ui-box-shadow-heavy;
          text-shadow: none;
        }

        &[aria-label="Previous slide"] {
          transform: translateX(-100vw);
        }

        &[aria-label="Next slide"] {
          transform: translateX(100vw);
        }
      }

      @include desktop {
        overflow: hidden; // scrolling only with prev/next buttons

        ~ [aria-controls] {
          &[aria-label="Previous slide"] {
            transform: translateX(-50%);
          }

          &[aria-label="Next slide"] {
            transform: translateX(50%);
          }
        }
      }
    }

    .carousel-indicators {
      bottom: auto;

      button {
        color: $black;
      }
    }

    &.bg\:grey [aria-live="polite"][tabindex] {
      &.--fade-left::before {
        background: linear-gradient(270deg, rgba($grey-100,0) 0%, $grey-100 100%);
      }

      &.--fade-right::after {
        background: linear-gradient(90deg, rgba($grey-100,0) 0%, $grey-100 100%);
      }
    }

    &.slide-width\:content {
      [aria-live="polite"][tabindex] {
        [aria-roledescription="slide"] {
          display: flow-root;
          width: auto;

          &, & > picture {
            width: auto;
          }
        }

        @include desktop {
          &::before,
          &::after {
            z-index: 1;
            display: none;
            content: "";
            position: absolute;
            width: $space-3xl;
            height: calc(100% - #{$box-shadow-offset});
          }

          &.--fade-left::before {
            display: block;
            left: 0;
            background: linear-gradient(270deg, rgba(#fff,0) 0%, #fff 100%);
          }

          &.--fade-right::after {
            display: block;
            right: 0;
            background: linear-gradient(90deg, rgba(#fff,0) 0%, #fff 100%);
          }
        }
      }
    }
  }

  .carousel-indicators,
  .carousel-thumbnails {
    display: none;
    margin: 0 !important;
    padding: 0;
    list-style-type: none;

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background: transparent;
      cursor: pointer;
    }

    @include tablet-landscape-up {
      display: flex;
    }
  }

  .carousel-indicators {
    z-index: 1; // keep above prev/next buttons
    position: absolute;
    bottom: min(5%, var(--space));
    left: 50%;
    transform: translateX(-50%);

    button {
      padding: $space-3xs;
      color: #fff;

      > .fas {
        opacity: .5;
      }
    }
  }

  .carousel-thumbnails {
    gap: var(--space-2xs);
    overflow-x: auto;
    margin-top: $thumbnails-margin-top !important;

    li {
      position: relative;
      min-width: $thumbnails-size;
      height: $thumbnails-size;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    button {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
