@import "config/config";

/**
 * Markup:
 * <div class="ui-component-loader
 *             variant:{linear, spinner}
 *             --active">
 *   <i class="far fa-spinner fa-spin fa-fw fa-3x" aria-hidden="true"></i> // `variant:spinner`
 * </div>
 */

.ui-component-loader {
  &.variant\:spinner {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-spinner;
    background-color: rgba(#fff, .8);

    > .fa-spinner {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      color: $sb-green;
    }

    &.position\:absolute {
      position: absolute;
    }
  }

  &.variant\:linear {
    height: 2px;
    position: relative;
    overflow: hidden;
    background-color: $grey-200;
    width: 100%;

    &::before {
      display: block;
      position: absolute;
      content: "";
      left: -100%;
      width: 100%;
      height: 2px;
      background-color: $sb-green;
      animation: loader--linear 1s linear infinite;
    }
  }

  &[hidden] {
    display: none !important;
  }
}

@keyframes loader--linear {
  0% {
    left: -30%;
    width: 30%;
  }

  40% {
    width: 50%;
  }

  80% {
    width: 120%;
  }

  100% {
    left: 100%;
  }
}
