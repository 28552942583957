.selection-summary {
  position: relative;
  margin-bottom: $spacing-unit--small;

  .content {
    max-width: 90%;
  }

  .selection-summary__change {
    color: $green;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .checkmark {
    color: $green;
  }
}

.nested-group {
  .treatment-option {
    padding-left: $spacing-unit;
  }
}

.treatment-option {
  margin-left: $spacing-unit;
}

.treatment-option__checkbox {
  float: left;
  margin-left: -$spacing-unit;
}

.treatment-option__text {
  &:hover {
    cursor: pointer;
  }
}

.treatment-option__title {
  margin-bottom: $spacing-unit--small;
}
