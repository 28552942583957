@import "config/config";

/**
  * Markup:
  * <{ul,ol} class="ui-component-list
  *                 gap:{none*, sm}
  *                 items:{default*, center}
  *                 layout:{stacked*, inline, columns}
  *                 variant:{icon, bullets}">
  *   <li>
  *     <i class="far fa-<icon>" aria-hidden="true"></i> // optional icon
  *     List text here
  *   </li>
  * </{ul,ol}>
  *
  * <{ul,ol} class="ui-component-list variant:selection">
  *   <li>
  *     <label class="ui-component-{checkbox, radio-button}">
  *       <input type="{checkbox, radio}">
  *       <span>Label text</span>
  *     </label>
  *   </li>
  * </{ul,ol}>
  *
  * Note: A list component is "bare" by default, i.e. no bullets. Use
  * `variant:icon` to use fontawesome icons as bullets. If icons are different within a
  * single list, consider using `fa-fw` to ensure consistent spacing.
  *
  * Note: For `variant:icon`, list items can contain a maximum of two children
  * including the icon. So if the text content contains other tags (`a`,
  * `span`, etc.) then the entirety needs to be wrapped in a `span` to ensure
  * max two children.
  */
.ui-component-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0 !important;
  list-style-type: none;
  text-align: left;

  &[hidden] {
    display: none !important;
  }

  &.gap\:sm {
    gap: var(--space-3xs);
  }

  &.items\:center {
    align-items: center;
  }

  &.layout\:inline {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.layout\:columns {
    @include phablet-up {
      display: block;
      columns: 2;
      column-gap: var(--space);

      > li:not(:last-child) {
        margin-bottom: var(--space-xs);
      }
    }

    @include tablet-portrait-up {
      columns: 3;
    }
  }

  &.variant\:icon {
    row-gap: $space-3xs;

    > li {
      display: flex;
      align-items: baseline;
      column-gap: $space-3xs;

      > i {
        flex-shrink: 0;
      }
    }
  }

  &.variant\:bullets {
    list-style-type: disc;
    margin: 0;
    padding-left: 2ch !important;
  }

  &.variant\:selection {
    width: 100%;
    gap: $space-sm;

    > li {
      color: $black;
      background-color: #fff;
      border: $ui-border;
      border-radius: $ui-border-radius;
      box-shadow: $ui-box-shadow;

      &:has(input:checked) {
        outline: solid $sb-green;

        .theme\:premium & {
          outline-color: $sb-gold;
        }
      }
    }


    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--space-sm);
      margin: 0;
      cursor: pointer;

      input + span {
        gap: var(--space-3xs);
      }
    }
  }
}
