@use "sass:math";

// https://github.com/FortAwesome/Font-Awesome/blob/5.13.1/scss/_variables.scss
$fa-li-width: 2em;
$fa-fw-width: math.div(20em, 16);

// https://github.com/FortAwesome/Font-Awesome/blob/5.13.1/scss/_list.scss#L6
$fa-ul-margin-left: $fa-li-width * math.div(5, 4);

@mixin fa-pseudo($unicode, $font-size: 1em, $font-weight: 400) {
  display: inline-block;
  content: $unicode;
  font-family: "Font Awesome 5 Pro";
  font-size: $font-size;
  font-weight: $font-weight; // 400 == regular, 900 == solid
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}
