@import "../ui_components/helpers/units"; // rem

:is(
  .controller\:bookings,
  .controller\:bookings\/klarna_sessions,
  .controller\:voucher_bookings,
  .controller\:voucher_bookings\/klarna_sessions,
  .controller\:klarna
) .checkout\:old {
  &.checkout\:complete,
  &.checkout\:error {
    .checkout\:hero {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $space;
      max-width: 200px;
      width: 100%;
    }

    > .ui-component-layout {
      > div:first-child {
        padding: $space-xxl $space;

        > div {
          max-width: 600px;
          margin: 0 auto;
        }
      }

      > div:last-child:not(:first-child) {
        align-self: stretch;
        padding-top: $space-3xl;
        padding-bottom: $space-3xl;
        background-color: $grey-100;

        > div {
          display: flex;
          flex-direction: column;
          gap: $space-xl;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          padding: 0 $space;
          max-width: 480px;
          min-height: 100%;
          text-align: center;
        }

        h2 {
          margin-bottom: 0;
        }
      }

      @include desktop {
        > div:first-child {
          padding-left: $space-3xl;
          padding-right: $space-xxl;
        }

        > div:last-child:not(:first-child) {
          max-width: 40%;
          background-image: url(../images/bg-account.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;

          > div {
            padding-left: $space-3xl;
          }
        }
      }
    }

    .ui-component-carousel {
      margin-bottom: $space-xl;

      ul {
        ~ button {
          display: none !important; // hide prev/next buttons
        }

        li i {
          line-height: 1.5;
        }
      }

      .carousel-indicators {
        display: flex !important; // don't hide on mobile
        gap: $space;
        top: calc(100% + $space-xs);

        @include tablet-landscape-up {
          gap: 0;
        }
      }
    }
  }

  .checkout\:payment-options {
    :has(> img) {
      display: flex;
      gap: var(--space-xs);
    }

    img {
      // https://docs.klarna.com/marketing/solutions/grab-and-go/gb/advertising-brand-guidelines/logos-trademarks/#clear-space
      min-height: 30px;
      margin-top: calc(.4 * 30px);
    }
  }

  .checkout\:page-layout {
    &, & > * {
      margin-bottom: 0 !important;
    }

    > * {
      flex-basis: 100%; // no summary on mobile, so this removes flex gap

      @include tablet-portrait-up {
        flex-basis: auto;
      }
    }
  }

  .checkout\:upgrades,
  .checkout\:delivery-options,
  .checkout\:extra-options {
    justify-content: center;
    margin-bottom: $space !important;
  }

  .checkout\:extra-options {
    .ui-component-counter {
      label {
        flex-shrink: 1;
      }

      input[size] {
        width: 100% !important;
      }
    }
  }

  .checkout\:product-modal {
    > [tabindex] {
      padding-top: var(--space-xl);
      padding-bottom: 0;

      > article > * {
        margin-bottom: 0;
      }

      .ui-component-carousel {
        &, & > div {
          height: auto;
        }
      }

      .ui-component-status-bar {
        padding-left: 0;
        padding-right: 0;
      }
    }

    header {
      text-align: center;
      padding-bottom: $space-sm;
      border-bottom: $ui-border;
      font-weight: 700;
      font-size: 0.85rem;
    }
  }

  .checkout\:address-results {
    @include forms--input-appearance;

    & {
      flex-basis: auto !important; // override fieldset descendant style
      width: auto;
      max-height: sb-rem(360px);
      overflow: auto;
    }

    label {
      font-weight: 400;
    }
  }

  .checkout\:summary-card {
    min-width: sb-rem(360px);
    max-width: sb-rem(600px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $space-lg;
    text-align: left;
    background-color: #fff;

    .ui-component-voucher {
      max-width: clamp(180px, 75%, 280px);
      min-width: auto;
      margin-top: calc(-1 * var(--space-3xl));
      margin-bottom: $space;
      font-size: 75%;

      .utils\:wave {
        font-size: 325%;

        &::after {
          max-height: 50%;
        }
      }
    }

    .accordion-item > :first-child {
      justify-content: center !important; // center "Details" toggle link
    }

    header {
      h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-xxs;
      }

      > button:last-child {
        opacity: 1 !important; // undo `header:raised` style
      }
    }

    p:last-child {
      margin-bottom: 0;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      th, td {
        padding-bottom: $space-3xs;
      }

      th + td {
        text-align: right;
        vertical-align: top;
      }

      tbody th, tfoot th {
        width: 75%;
        font-weight: 400;
      }

      tbody tr:last-child {
        th, td {
          padding-bottom: $space-sm;
        }
      }

      tfoot {
        tr:first-child, tr:last-child {
          border-top: $ui-border;

          th, td {
            padding-top: $space-xs;
            padding-bottom: $space-sm;
            font-weight: 700;
          }
        }

        tr:last-child {
          th, td {
            padding-bottom: 0;
          }
        }
      }
    }

    small {
      display: inline-flex;
      align-items: baseline;
      line-height: $space-sm;

      i {
        margin-right: $space-3xs;
      }
    }

    .ui-component-list > li:not(:last-child) {
      margin-bottom: $space-sm;
    }
  }

  .checkout\:sidebar-summary {
    display: none;

    @include tablet-portrait-up {
      display: flex;
      align-self: flex-start;
    }

    ul {
      margin-bottom: 0;
    }
  }

  .checkout\:main-summary {
    @include tablet-portrait-up {
      display: none;
    }
  }

  .checkout\:applied-discounts {
    padding: 0 $space-xs;
    background: #fff;
    border: $ui-border;
    border-radius: $ui-border-radius;

    .ui-component-notice {
      margin-top: $space-xs;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr:not(:last-child) {
        border-bottom: $ui-border;
      }

      td {
        padding: $space-xs 0;

        &:not(:first-child) {
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

  .checkout\:savvy-inputs {
    @media only screen and (min-width: map-get($_mq-breakpoints, tablet-portrait-up)) and (max-width: 1099px) {
      flex-wrap: wrap !important;
    }

    > label, > fieldset {
      flex-basis: 100%;
    }

    > fieldset {
      margin-bottom: 0;
    }
  }

  .checkout\:discount-modal-content {
    h3 {
      margin-top: $space;
      margin-bottom: $space-xxs;
      padding-bottom: $space-xxs;
      border-bottom: $ui-border;
    }
  }

  [hidden] {
    display: none;
  }

  .treatment-terms {
    font-size: 0.875rem;
  }
}

:is(.controller\:bookings, .controller\:klarna) .checkout\:old.checkout\:complete {
  .ui-component-notice header {
    font-weight: 400;
  }
}
