@import "config/config";
@import "helpers/media-queries";
@import "helpers/wrapper";

@mixin notice--stacked {
  > div {
    flex-direction: column;
    gap: calc(var(--size) / var(--space-scale)) !important;
  }

  &:not(.align\:center) {
    > div {
      align-items: flex-start;
    }

    header {
      justify-content: flex-start;
    }
  }

  header, p, ul, ol {
    width: 100%;
    margin-bottom: 0 !important;
  }

  header {
    flex-direction: row;
    padding-bottom: calc(var(--size) / var(--space-scale));
    padding-right: 0;
    border-bottom: 1px solid $grey-200;
    border-right: 0;
  }

  .ui-component-button.variant\:dismiss {
    position: absolute;
    top: $space-3xs;
    right: $space-3xs;
  }
}

@mixin notice--color-selectors {
  a,
  i,
  .ui-component-button.variant\:dismiss,
  .ui-component-button.variant\:link,
  &.variant\:alt header {
    @content;
  }
}

/**
  * Markup:
  * <{aside,article} class="ui-component-notice
  *                         width:{full*, content}
  *                         align:{left*, center}
  *                         borders:{auto*, none}
  *                         size:{sm, md*, lg} // refers to padding/spacing
  *                         stacked:{never*, mobile, always} // header/content in row or column
  *                         variant:{alt}
  *                         theme:{default*, primary, info, alert, warning, promo, rewards, transparent}">
  *   <div>
  *     <header> // optional header
  *       <{i,img}/> // optional icon image
  *       <div>Optional heading text here</div>
  *     </header>
  *     <p></p> // or `ul`, etc
  *     <button class="ui-component-button variant:dismiss"></button> // optional dismiss button
  *   </div>
  * </{aside,article}>
  *
  * Note: A `margin-bottom` is automatically added when this component is
  * applied to the `article` element.
  */
.ui-component-notice {
  position: relative; // for optional dismiss button
  display: flex;
  width: 100%;
  padding: var(--size);
  font-weight: 400;
  text-align: left;
  color: $black;
  background-color: $grey-100;
  border: 1px solid $grey-200;

  &[hidden] {
    display: none !important;
  }

  &.variant\:alt {
    background-color: transparent;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: var(--size);
    width: 100%;
    @include wrapper;

    > :not(header):not(:last-child) {
      flex: 1;
    }

    > :last-child:not(button) {
      margin-bottom: 0 !important;
    }
  }

  header, p, ul, ol {
    width: auto;
    margin-bottom: 0 !important;
  }

  header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch; // ensure border is full-height
    justify-content: center;
    padding-right: var(--size);
    border-right: 1px solid $grey-200;
    font-size: $base-font;
    font-weight: 700;

    // normalize heading (regardless of type, e.g. `h2`, `div`, etc.)
    > :not(i) {
      margin-bottom: 0 !important;
    }

    i, img {
      ~ * {
        margin-left: calc(var(--size) / var(--space-scale) / var(--space-scale));
      }

      &.fa-2x ~ * {
        margin-left: calc(var(--size) / var(--space-scale));
      }
    }

    @include phablet-up {
      flex-direction: row;
    }
  }

  &.variant\:alt header {
    border: none !important;
    padding: var(--space-3xs) !important;
  }

  p {
    font-size: $base-font;
  }

  ul, ol {
    padding-left: $space-sm;
  }

  &:not(.align\:center) > div {
    margin: 0; // undo `margin: 0 auto` from wrapper otherwise it's centered
    justify-content: left;
  }

  &.align\:center {
    margin-left: auto;
    margin-right: auto;

    &, > div, header {
      text-align: center;
    }

    > div {
      display: inline-flex;
    }

    header ~ p {
      text-align: left; // entire notice is centered but left-align content to header
    }

    * {
      justify-content: center;
    }
  }

  &:not(.borders\:none) {
    border-radius: $ui-border-radius-lg;
  }

  &.borders\:none {
    border: none;
  }

  &,
  &.size\:md {
    --size: var(--space-sm);
  }

  &.size\:sm {
    --size: var(--space-2xs);
  }

  &.size\:lg {
    --size: var(--space);
  }

  &.width\:content {
    max-width: max-content;
    flex-basis: auto !important; // enforce width if notice is a flex child
  }

  &.stacked\:mobile,
  &.variant\:alt {
    @include tablet-portrait-max {
      @include notice--stacked;
    }
  }

  &.stacked\:always {
    @include notice--stacked;
  }

  @include notice--color-selectors {
    color: $black;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
  }

  .ui-component-button.variant\:dismiss {
    width: auto;

    &::before {
      font-size: 1rem;
    }
  }

  &.theme\:primary {
    &:not(.variant\:alt),
    &.variant\:alt header {
      background-color: $sb-green-100;
    }

    &, header {
      border-color: $sb-green-200 !important; // override :not(.flex:column) specificity
    }

    @include notice--color-selectors {
      color: $sb-green;
    }
  }

  &.theme\:info {
    &:not(.variant\:alt),
    &.variant\:alt header {
      background-color: $sb-blue-100;
    }

    &, header {
      border-color: $sb-blue-200 !important;
    }

    @include notice--color-selectors {
      color: $sb-blue;
    }
  }

  &.theme\:alert {
    &:not(.variant\:alt),
    &.variant\:alt header {
      background-color: $sb-red-100;
    }

    &, header {
      border-color: $sb-red-200 !important;
    }

    @include notice--color-selectors {
      color: $sb-red;
    }
  }

  &.theme\:warning {
    &:not(.variant\:alt),
    &.variant\:alt header {
      background-color: $sb-yellow-100;
    }

    &, header {
      border-color: $sb-yellow-200 !important;
    }

    @include notice--color-selectors {
      color: $sb-yellow;
    }
  }

  &.theme\:promo {
    background-color: $sb-green;
    color: #fff;

    &, header {
      border-color: $sb-green-100 !important;
    }

    @include notice--color-selectors {
      color: #fff;
    }
  }

  &.theme\:rewards {
    border-color: $rewards-blue;
    background-color: $rewards-blue;
    color: #fff;

    header {
      border-color: #fff !important;
    }

    @include notice--color-selectors {
      color: #fff;
    }
  }

  &.theme\:transparent {
    background-color: transparent;

    @include notice--color-selectors {
      color: $sb-green;
    }
  }
}

article.ui-component-notice {
  margin-bottom: $space;
}
